<template>
  <div class="content content--home">

    <nav v-if="this.parkInfo" class="menu">
      <router-link class="btn" :to="{ name: 'ParkEvents' }">
        What's on in the park
        <svg class="icon">
          <use xlink:href="@/assets/icons.svg#entertainment"></use>
        </svg>
      </router-link>
      <router-link class="btn" :to="{ name: 'ParkAttractions' }">
        Local attractions
        <svg class="icon">
          <use xlink:href="@/assets/icons.svg#cheers"></use>
        </svg>
      </router-link>
      <router-link class="btn" :to="{ name: 'ParkFeatures' }">
        Park features
        <svg class="icon">
          <use xlink:href="@/assets/icons.svg#swimming"></use>
        </svg>
      </router-link>
      <a v-if="mapUrl" class="btn" :href="mapUrl" target="_blank">
        Park map
        <svg class="icon">
          <use xlink:href="@/assets/icons.svg#map"></use>
        </svg>
      </a>
      <router-link class="btn" :to="{ name: 'ParkContact' }">
        Contact
        <svg class="icon">
          <use xlink:href="@/assets/icons.svg#phone"></use>
        </svg>
      </router-link>
      <a class="btn btn--secondary" :href="parkLink" target="_blank">Make a booking</a>
    </nav>

  </div>
</template>

<script>
import constants from '../../constants';


export default {
  name: 'ParkLanding',
  props: ['parkInfo'],
  components: {
  },
  data() {
    return {
      constants: constants,
      parkLink: typeof this.parkInfo?.park?.parkUrl === "undefined" ?
        constants.kenticoBaseUrl : constants.kenticoBaseUrl + this.parkInfo?.park?.parkUrl
    }
  },
  mounted() {
    document.body.classList.add('page--' + this.$route.name.toLowerCase());
  },
  unmounted() {
    document.body.classList = "";

  },
  computed: {
    mapUrl() {
      // Check to make sure a park map is present
      let parkMap = false;
      const parkDownloads = this.parkInfo.downloads;
      if (parkDownloads.length >= 1) {
        // Try find the right park map to display
        parkDownloads.forEach(function(download) {
          if(download.displayName.includes('Map') && download.url != "") {
              parkMap = download;
          }
        });

        if(parkMap)  {
          return constants.CDNBaseUrl + parkMap.url.substring(1)
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  },

}
</script>
