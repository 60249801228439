// parkService.js
import axios from 'axios';
import constants from '@/constants.js';
const parkJsonEndpoint = constants.parkJsonEndpoint;
const apiClient = axios.create({
  baseURL: parkJsonEndpoint,
  withCredentials: false,
  headers: {},
});

export default {
  getPark(parkCode) {
    return apiClient.get(parkCode + '.json');
  },
  getParks() {
    return apiClient.get('Parks.json');
  },
};