<template>
  <Breadcrumb :pageTitle="pageTitle" :parkCode="this.parkCode" />

  <div class="content content--attractions">

    <div class="attractions">
      <AttractionCard v-for="attraction in attractions" :key="attraction.id" :attraction="attraction" />
    </div>
  </div>
</template>

<script>
import AttractionCard from "@/components/AttractionCard.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";

export default {
  name: 'Attractions',
  props: ['parkCode'],
  components: {
    AttractionCard,
    Breadcrumb
  },
  mounted() {
    document.body.classList.add('page--' + this.$route.name.toLowerCase());
  },
  unmounted() {
    document.body.classList = "";

  },
  computed: {
    parkInfo() {
      return this.$store.state.parkInfo
    },
    attractions() {
      let attractions =  this.$store.state.parkInfo.activities
      attractions = attractions.filter(function(attraction) {
        return (attraction.isActive && (attraction.typeId === 0 || attraction.typeId === 2))
      });
      attractions.forEach(function(attraction) {
       if(attraction.image.includes('.com') || attraction.image.includes('gdayparks.com')) { // If the image is a full URL, replace the domain with a tilde
          attraction.image = attraction.image.replace(new RegExp('^https?://[^/]+'), '~');
        } 
      });
      return attractions;
    },
    pageTitle() {
      return 'Local Attractions'
    }
  }

}
</script>