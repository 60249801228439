<template>
  <div class="event-card">
    <div class="event__header">

      <!--img :src="'https://prod-stratweb-gdaygroup.imgix.net' + event.image.substring(1)" /-->
      <!-- Activity images are sent with the full URL, no need to substring or CDN -->
      <img :src="event.image.includes('~/') ? constants.CDNBaseUrl + event.image.substring(1) : event.image" />

      <div class="event__date">
        {{ dateDay }}<span class="data__month">{{ dateMonth }}</span>
      </div>
    </div>
    <h3>{{ event.displayName }}</h3>
    <ul class="event__meta">
      <li v-if="event.time != ''">
        <p>
          <span>When</span>
          {{ event.time }}
        </p>

      </li>
      <li v-if="event.duration != ''">
        <p>
          <span>Duration</span>
          {{ event.duration }}
        </p>
      </li>
      <li v-if="event.venueName != ''">
        <span>Where</span>
        {{ event.venueName }}
      </li>
      <li v-if="event.cost > 0">
        <span>Cost</span>
        ${{ event.cost }}
      </li>
    </ul>

    <div class="event__content" v-html="event.description"></div>

  </div>
</template>

<script>
import constants from '@/constants';
export default {
  name: "EventCard",
  props: {
    event: Object
  },
  data() {
    return {
      constants: constants,
    };
  },
  computed: {
    dateDay() {
      const sDate = new Date(this.event.startDate).getDate();
      const formattedDate = (sDate < 10 ? '0' : '') + sDate;
      return formattedDate
    },
    dateMonth() {
      const formatter = new Intl.DateTimeFormat('en', { month: 'short' });
      const formattedMonth = formatter.format(new Date(this.event.startDate));
      return formattedMonth
    }
  }
};
</script>

