<template>

  <header class="header header--network">
      <router-link 
      class="logo" 
      to="https://discoveryholidayparks.com.au"
      target="_blank"
      >
        <img src="@/assets/logo.svg" />
      </router-link>
  </header>

  <div class="content content--network">
    <h1>Oh Oh!</h1>
    <h3>It looks like you're experiencing some network issues</h3>
    <p>You can try again with the button below</p>
    <button
      @click="$router.go(-1)"
      class="btn"
    >
    Try Again
    </button>
    <p class="small">If you're still having troubles you might need to try again later.</p>
    <p class="text-center">
      <router-link 
        :to="{name: 'Home' }"
        >
        Return to list of Parks
      </router-link>
    </p>
  </div>

</template>
