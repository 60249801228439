<template>
 
    <div class="breadcrumb">
        <button @click="$router.push({ name: 'ParkLanding', params: parkCode })">
            <svg class="icon"><use xlink:href="@/assets/icons.svg#chevronLeft"></use></svg>
        </button>
        <h3>
            <svg v-if="currentRouteName === 'ParkEvents'" class="icon"><use xlink:href="@/assets/icons.svg#entertainment"></use></svg>
            <svg v-if="currentRouteName === 'ParkAttractions'" class="icon"><use xlink:href="@/assets/icons.svg#cheers"></use></svg>
            <svg v-if="currentRouteName === 'ParkFeatures'" class="icon"><use xlink:href="@/assets/icons.svg#swimming"></use></svg>
            <svg v-if="currentRouteName === 'ParkContact'" class="icon"><use xlink:href="@/assets/icons.svg#phone"></use></svg>
            {{ pageTitle }}
        </h3>
    </div>
 
</template>

<script>
// { name: 'Home', query: { park: 'npam' }}
export default {
  name: "Breadcrumb",
  props: {
    pageTitle: String,
    parkCode: String
  },
  created(){
      //console.log('code = ' + parkCode)
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    }
  }
};
</script>

