<template>

    <PageHeader :parkInfo="parkInfo" />

    <router-view :parkInfo="parkInfo" :parkCode="parkCode" />

</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import constants from "@/constants";
export default {
  props: ['parkCode'],
  components: {
    PageHeader
  },
  data() {
    return {
      constants: constants,
    }
  },
  setup() {
  },
  created() {
  },
  computed: {
    parkInfo(){
      return this.$store.state.parkInfo
    },
  },
  mounted () {
    
  },
  unmounted () {
  }

}
</script>