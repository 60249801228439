import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import ParkLayout from "../views/park/Layout.vue";
import ParkLanding from "../views/park/Landing.vue";
import ParkFeatures from "../views/park/Features.vue";
import ParkAttractions from "../views/park/Attractions.vue";
import ParkContact from "../views/park/Contact.vue";
import ParkEvents from "../views/park/Events.vue";
import NotFound from "../views/NotFound.vue";
import NetworkError from "../views/NetworkError.vue";
import store from '@/store'

const routes = [
  {
    path: "/",
    name: "Home",
    props: true,
    component: Home,
    beforeEnter: () => {
      return store.dispatch('fetchParks')
      .catch(error => {
        console.log(error);

        if (error.response && error.response.status == 404) {
          return{
            name: '404Resource',
            params: { resource: 'park' }
          }
        } else {
          return { name: 'NetworkError' }
        }

      })
    }
  },
  {
    path: '/park/:parkCode',
    name: 'ParkLayout',
    props: true,
    component: ParkLayout,
    beforeEnter: to => {
      return store.dispatch('fetchPark', to.params.parkCode.toUpperCase())
      .catch(error => {
        console.log(error);
        if (error.response && error.response.status == 404) {
          return {
            name: '404Resource',
            params: { resource: 'park' }
          }
        } else {
          return { name: 'NetworkError' }
        }
        
      })
    },
    children: [
      {
        path: '',
        name: 'ParkLanding',
        component: ParkLanding
      },
      {
        path: 'features',
        name: 'ParkFeatures',
        component: ParkFeatures
      },
      {
        path: 'contact',
        name: 'ParkContact',
        component: ParkContact
      },
      {
        path: "attractions",
        name: "ParkAttractions",
        component: ParkAttractions
      },
      {
        path: "events",
        name: "ParkEvents",
        component: ParkEvents
      },
    ]
  },
  {
    path: '/:catchAll(.*)',
    name: NotFound,
    component: NotFound
  },
  {
    path: '/404/:resource',
    name: '404Resource',
    component: NotFound,
    props: true
  },
  {
    path: '/network-error',
    name: 'NetworkError',
    component: NetworkError
  }

  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
});

export default router;
