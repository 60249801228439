<template>
  <div class="attraction-card">
    <!--img :src="'https://prod-stratweb-gdaygroup.imgix.net' + attraction.image.substring(1)" /-->
    <img :src="CDNBaseUrl + attraction.image.substring(1)" />
    <h3>{{ attraction.displayName }}</h3>
    <span v-if="attraction.distanceFromPark != ''" class="distance">
      {{ attraction.distanceFromPark }} from the park
    </span>
    <div class="attraction__content" v-html="attraction.description"></div>

    <a v-if="attraction.url != ''" class="button" :href="attraction.url" target="_blank">Read More</a>

  </div>
</template>

<script>
import constants from '@/constants.js';
export default {
  name: "AttractionCard",
  props: {
    attraction: Object
  }, 
  data() {
    return {
      constants: constants,
      CDNBaseUrl: constants.CDNBaseUrl
    };
  }
}
</script>