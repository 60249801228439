<template>
  <Breadcrumb :pageTitle="pageTitle" :parkCode="parkCode" />

  <div class="content content--events">
    <EventCard v-for="event in events" :key="event.id" :event="event" />

    <div v-if="events.length === 0">
      <h1>Where did they go?</h1>
      <p>Sorry, looks like we need to add more events to the database.<br /><br />
        reach out via phone or email and our friendly staff will be able to give you an update in the interim.</p>
    </div>

  </div>
</template>

<script>
import EventCard from "@/components/EventCard.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import moment from 'moment';

export default {
  name: 'Events',
  props: ['parkCode'],
  components: {
    Breadcrumb,
    EventCard
  },
  mounted() {
    document.body.classList.add('page--' + this.$route.name.toLowerCase());
  },
  unmounted() {
    document.body.classList = "";

  },
  methods: {
  },
  computed: {
    pageTitle() {
      return 'What\'s on in the Park'
    },
    events() {

      const EVENT_FUTURE_MONTHS = 3; // Total months in advance to show on the page
      moment().format();
      // Add Days prototype
      Date.prototype.addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      }

      // Add Hours prototype
      Date.prototype.addHours = function (h) {
        this.setTime(this.getTime() + (h * 60 * 60 * 1000));
        return this;
      }


      //typeId = 1 for in park events
      var payload = this.$store.state.parkInfo.activities;

      // filter incoming payload with typeId = 1 and where interval and recurringDay is non-empty.
      // Empty interval and recurringDay are excluded as those have invalid endDate
      var data = payload.filter(function (el) {
        return (el.isActive == 1 && (el.typeId == 1 || el.typeId == 3 ));
      });
      //console.log(data);

      function generateEventOccurrences(eventObj, months) {
        const occurrences = [];
        const startDate = new Date(eventObj.startDate);
        const endDate = new Date(eventObj.endDate);
        const recurringDays = eventObj.recurringDay.split(';').map(day => day.trim()); // Split recurring days by semicolon
        
        if(recurringDays == "" && eventObj.endDate == "0001-01-01T00:00:00")  {// Handle empty end date
          occurrences.push({
            id: eventObj.id,
            displayName: eventObj.displayName,
            startDate: startDate,
            endDate: startDate,
            time: eventObj.time,
            duration: eventObj.duration,
            interval: eventObj.interval,
            recurringDay: eventObj.recurringDay,
            image: eventObj.image,
            description: eventObj.description,
            venueName: eventObj.venueName,
            venueAddress: eventObj.venueAddress,
            displayAfter: eventObj.displayAfter,
            displayBefore: eventObj.displayBefore,
            journeyIds: eventObj.journeyIds,
            cost: eventObj.cost,
          });
        } else if(recurringDays == "") {
            const currentDate = new Date();
            // If the end date is still in the future, show this - start date here is not as relevant
            //console.log(eventObj.displayName,currentDate, endDate);
            if(currentDate <= endDate) {
              occurrences.push({
                id: eventObj.id,
                displayName: eventObj.displayName,
                startDate: startDate,
                endDate: endDate,
                time: eventObj.time,
                duration: eventObj.duration,
                interval: eventObj.interval,
                recurringDay: eventObj.recurringDay,
                image: eventObj.image,
                description: eventObj.description,
                venueName: eventObj.venueName,
                venueAddress: eventObj.venueAddress,
                displayAfter: eventObj.displayAfter,
                displayBefore: eventObj.displayBefore,
                journeyIds: eventObj.journeyIds,
                cost: eventObj.cost,
              });
            }
        } else {

          // Loop through each month
          for (let i = 0; i < months; i++) {
            const currentDate = new Date(startDate.getFullYear(), startDate.getMonth() + i, 1);

            // Loop through each day of the month
            while (currentDate.getMonth() === startDate.getMonth() + i) {
              // Check if the day matches any of the recurring days and is within the start/end date range
              if (recurringDays.includes(getDayOfWeekName(currentDate.getDay())) && currentDate >= startDate && currentDate <= endDate) {
                occurrences.push({
                  id: eventObj.id,
                  displayName: eventObj.displayName,
                  startDate: new Date(currentDate),
                  endDate: new Date(currentDate),
                  time: eventObj.time,
                  duration: eventObj.duration,
                  interval: eventObj.interval,
                  recurringDay: eventObj.recurringDay,
                  image: eventObj.image,
                  description: eventObj.description,
                  venueName: eventObj.venueName,
                  venueAddress: eventObj.venueAddress,
                  displayAfter: eventObj.displayAfter,
                  displayBefore: eventObj.displayBefore,
                  journeyIds: eventObj.journeyIds,
                  cost: eventObj.cost,
                });
              }
              currentDate.setDate(currentDate.getDate() + 1);
            }
          }
        }
        return occurrences;
      }

      function getDayOfWeekName(dayIndex) {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return days[dayIndex];
      }

      let events = [];
      //console.log(data);
      data.forEach((event) => {
        if(event.image.includes('.com') || event.image.includes('gdayparks.com')) { // If the image is a full URL, replace the domain with a tilde
          event.image = event.image.replace(new RegExp('^https?://[^/]+'), '~');
        }
        const occurrences = generateEventOccurrences(event, EVENT_FUTURE_MONTHS);
        //console.log(occurrences);
        occurrences.forEach((item) => { events.push(item); })
      });

      //console.log(events)
      // Remove events from the past
      let sortedEvents = events.filter((item) => {
        // We want to ensure the event has started (or is still on going )
        if(new Date(item.endDate) >= new Date()) {
          //console.log(new Date(item.endDate), new Date());
          return true;
        } else {
          return false;
        }
      })
      //console.log('gottem')
      sortedEvents = sortedEvents.sort((a, b) => {
        return new Date(a.startDate) - new Date(b.startDate);
      });
      //console.log("Events finished");
      return sortedEvents;
    }
  }
}
</script>