import { createStore } from 'vuex'
import ParkService from "@/services/ParkService.js";

export default createStore({
    state: {
        parkInfo: {},
        parks: {} 
    },
    mutations: {
        SET_PARK(state, parkInfo) {
            state.parkInfo = parkInfo
        },
        SET_PARKS(state, parks) {
            state.parks = parks
        }
    },
    actions: {
        fetchPark({ commit }, parkCode) {
            /* Add check to see if Store populated save calling API each home page visit */
            //console.log(parkCode);

            return ParkService.getPark(parkCode)
                .then(response => {
                    //console.log(response.data.ParkInfo)
                    //this.parkInfo = response.data.ParkInfo
                    commit('SET_PARK', response.data.ParkInfo)
                })
                .catch (error => {
                    if (error.response && error.response.status == 404) {
                        return {
                          name: '404Resource',
                          params: { resource: 'park' }
                        }
                    } else {
                        return { name: 'NetworkError' }
                    }
            })
            
        },
        fetchParks({ commit }) {
            return ParkService.getParks()
                .then(response => {
                    //console.log(response.data)
                    //console.log("Response:",response.data)
                    let filteredParks = response.data.filter(
                        park => park.owner === "Discovery Parks"
                        && park.isActive == true)
                    //console.log("Filtered Parks:",filteredParks)
                    commit('SET_PARKS', filteredParks)
                })
                .catch (() => {
                    this.$router.push({ name: 'NetworkError' }) 
            })
            
        }
    },
    modules: {}
})