<template>
  <Breadcrumb :pageTitle="pageTitle" :parkCode="parkInfo.park.crmCode" />

  <div class="content content--features">
    <div v-for="feature in features" :key="feature.id">
      <!--img :src="'https://prod-stratweb-gdaygroup.imgix.net' + feature.image" width="25" height="25" /-->
      <div class="feature-with-info">
        <span v-if="feature.info != ''" @click="handleAccordion" class="chevron "></span>
        <!--<img :src="constants.CDNBaseUrl + feature.image" width="25" height="25" />-->
        <h3 class="feature-title">{{ feature.displayName }}</h3>
        <div class="feature-info"> {{ feature.info }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import constants from "../../constants";



export default {
  name: 'Features',
  props: ['parkCode'],
  components: {
    Breadcrumb
  },
  data() {
    return {
      constants: constants
    }
  },
  mounted() {
    document.body.classList.add('page--' + this.$route.name.toLowerCase());
  },
  unmounted() {
    document.body.classList = "";

  },
  methods: {
    handleAccordion(event) {
      // Prevent default link behavior
      event.preventDefault();
      let content = event.target.parentNode ;
      // If the content is already expanded, collapse it and quit
      if (content.classList.contains('active')) {
        content.classList.remove('active');
        return;
      }

      // Get all open accordion content, loop through it, and close it
      var accordions = document.querySelectorAll('.feature-with-info.active');
      for (var i = 0; i < accordions.length; i++) {
        accordions[i].classList.remove('active');
      }

      // Toggle our content
      content.classList.toggle('active');
    }
  },
  computed: {

    parkInfo() {
      return this.$store.state.parkInfo
    },
    features() {
      let parkFeatures = this.$store.state.parkInfo.park.content.features
      // Sort Alphabetically
      parkFeatures = parkFeatures.sort((a, b) => {
        let fa = a.displayName.toLowerCase(), fb = b.displayName.toLowerCase();
        if (fa < fb) {
          return -1
        }
        if (fa > fb) {
          return 1
        }
        return 0
      })

      return parkFeatures
    },
    pageTitle() {
      return 'Park Features'
    }
  }

}
</script>

