<template>
  <Breadcrumb :pageTitle="pageTitle" :parkCode="this.parkCode" />

  <div class="content content--contact">
    <div class="two-col">
    <div class="contact-item">
        <p>Phone</p>
        <a :href="'tel:' + parkInfo.park.content.phone">{{ parkInfo.park.content.phone }}</a>
      </div>
      <div v-if="parkInfo.park.content.freecall != ''" class="contact-item">
        <p>Freecall</p>
        <a :href="'tel:' + parkInfo.park.content.freecall">{{ parkInfo.park.content.freecall }}</a>
      </div>
      
      </div>
      <div class="contact-item">
        <p>Address</p>
        <a :href="'https://www.google.com/maps/search/?api=1&query=' + parkInfo.park.content.addressStreet + ',' + parkInfo.park.content.addressTown"
          target="_blank">{{ parkInfo.park.content.addressStreet }}, {{ parkInfo.park.content.addressTown }}, {{
            parkInfo.park.content.addressState }}, {{ parkInfo.park.content.addressPostcode }}</a>
      </div>
      <div class="contact-item">
        <p>Email </p>
        <a :href="'mailto:' + parkInfo.park.content.email">{{ parkInfo.park.content.email }}</a>
      </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";

export default {
  name: 'Contact',
  props: ['parkCode'],
  components: {
    Breadcrumb
  },
  mounted() {
    document.body.classList.add('page--' + this.$route.name.toLowerCase());
  },
  unmounted() {
    document.body.classList = "";

  },
  computed: {
    parkInfo() {
      return this.$store.state.parkInfo
    },
    pageTitle() {
      return 'Contact'
    }
  }

}
</script>
