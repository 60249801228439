<template>
     
    <header class="header header--error">
        <router-link 
        class="logo" 
        to="https://discoveryholidayparks.com.au"
        target="_blank"
        >
          <img src="@/assets/logo.svg" />
        </router-link>
    </header>

    <div class="content content--404">
        <h1>Oh no!</h1>
        <h3>Sorry, the {{ resource }} you're looking for could not be found.</h3>
        <p>Navigate over to our list of parks to find the park you're after.</p>
        <router-link 
            :to="{name: 'Home' }"
            class="btn"
        >
        View full list of parks
        <svg class="icon"><use xlink:href="@/assets/icons.svg#list"></use></svg>
        </router-link>
    </div>
</template>

<script>
export default {
    props: {
        resource: {
            type: String,
            required: true,
            default: 'page'
        }
    },
    
}
</script>


